import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { Footer, PageBootstrap, NamedLink } from '../../components';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { TopbarContainer } from '../../containers';
import metaImage from '../../assets/ogp.jpg';

import { propTypes } from '../../util/types';

import medialogo1 from './img/medialogo1.png';
import medialogo2 from './img/medialogo2.png';
import medialogo3 from './img/medialogo3.png';
import medialogo4 from './img/medialogo4.png';
import medialogo5 from './img/medialogo5.png';
import medialogo6 from './img/medialogo6.png';
import medialogo7 from './img/medialogo7.png';
import './landingpage.scss';

import trekkingImg from './img/activities_trekking.jpg';
import skiImg from './img/activities_skiSnowboarding.jpg';
import bikingImg from './img/activities_mountainBiking.jpg';
import rockClimbingImg from './img/activities_mountaineering.jpg';
import fishingImg from './img/activities_fishing.jpg';
import kayakImg from './img/activities_kayaking.jpg';
import surfingImg from './img/activities_surfingDiving.jpg';
import supImg from './img/activities_sup.jpg';
import yogaImg from './img/activities_yogaMeditation.jpg';
import farmImg from './img/activities_farmExperience.jpg';
import multidayImg from './img/activities_multiday.webp';
import campingImg from './img/activities_camping.webp';
import canyoningImg from './img/activities_canyoning.webp';

const activityArray = [
  {
    key: 'escapes',
    image: multidayImg,
  },
  {
    key: 'skiSnowboarding',
    image: skiImg,
  },
  {
    key: 'trekking',
    image: trekkingImg,
  },
  {
    key: 'camping',
    image: campingImg,
  },
  {
    key: 'mountainBiking',
    image: bikingImg,
  },
  {
    key: 'mountaineering',
    image: rockClimbingImg,
  },
  {
    key: 'canyoning',
    image: canyoningImg,
  },
  {
    key: 'kayaking',
    image: kayakImg,
  },
  {
    key: 'surfingDiving',
    image: surfingImg,
  },
  {
    key: 'sup',
    image: supImg,
  },
  {
    key: 'fishing',
    image: fishingImg,
  },
  {
    key: 'yogaMeditation',
    image: yogaImg,
  },
  {
    key: 'farmExperience',
    image: farmImg,
  },
];

export const LandingPageComponent = props => {
  const { intl } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${metaImage}`;

  const activityTypes = (
    <ul className="activityCards">
      {activityArray.map(element => (
        <li key={element.key} className="activityCard">
          <NamedLink
            id="banner_activities"
            name="SearchPage"
            params={{ locale: config.locale }}
            to={{ search: `?pub_activities=has_any%3A${element.key}` }}
          >
            <img src={element.image} loading="lazy" />
            <p className="activityName">
              <FormattedMessage id={`Activities.${element.key}`} />
            </p>
          </NamedLink>
        </li>
      ))}
    </ul>
  );

  return (
    <PageBootstrap
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: metaImage, width: 1200, height: 630 }]}
      twitterImages={[{ url: `${config.canonicalRootURL}${metaImage}`, width: 600, height: 314 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'GuidesPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <TopbarContainer />

      <Container fluid>
        <div className="hero-landingpage">
          <div className="background-svg"></div>
        </div>
      </Container>

      <Container>
        <Row className="justify-content-center">
          <Col lg={9} md={8} sm={8} xs={12}>
            <div className="whoarewe">
              <p className="text-adventures">
                <FormattedMessage id="LandingPage.whoWeAre1" />
              </p>
              <p className="text-adventures">
                <FormattedMessage id="LandingPage.whoWeAre2" />
              </p>
              <div className="button-submit">
                <NamedLink name="AdventuresPage">
                  <FormattedMessage id="LandingPage.whoWeAreTextLink" />
                </NamedLink>
              </div>

              <div className="landing-page-separator"></div>
            </div>
          </Col>
        </Row>

        <section>
          <Row>
            <Col lg={12} md={8} sm={8} xs={12} className="featuredon">
              <div className="landing-page-sectiontitle">Featured on</div>
              <div className="landing-page-media-logos">
                <p className="landing-page-media-logo">
                  <a
                    href="https://www.forbes.com/sites/joanneshurvell/2023/06/23/kammui-guides-new-japanese-travel-platform-offers-ultimate-insider-nature-experiences/"
                    target="_blank"
                    id="icon_forbes"
                  >
                    <img src={medialogo1} />
                  </a>
                </p>
                <p className="landing-page-media-logo">
                  <a
                    href="https://www.japan.travel/en/uk/inspiration/unveiling-new-horizons-in-japan/"
                    target="_blank"
                    id="icon_jnto"
                  >
                    <img src={medialogo2} />
                  </a>
                </p>
                <p className="landing-page-media-logo">
                  <a
                    href="https://hypebeast.com/2022/12/kammui-japan-outdoor-experience-guide-info"
                    target="_blank"
                    id="icon_hypebeast"
                  >
                    <img src={medialogo3} />
                  </a>
                </p>
                <p className="landing-page-media-logo">
                  <a
                    href="https://www.jal.co.jp/ar/en/guide-to-japan/destinations/articles/kyoto/embark-upon-adventure-travel.html"
                    target="_blank"
                    id="icon_jal"
                  >
                    <img src={medialogo4} />
                  </a>
                </p>
                <p className="landing-page-media-logo">
                  <a
                    href="https://www.japantimes.co.jp/life/2023/10/22/food-drink/stuart-brioza-hokkaido-tour/"
                    target="_blank"
                    id="icon_japantimes"
                  >
                    <img src={medialogo5} />
                  </a>
                </p>
                <p className="landing-page-media-logo">
                  <img src={medialogo6} />
                </p>
                <p className="landing-page-media-logo">
                  <img src={medialogo7} />
                </p>
              </div>
              <div className="landing-page-separator"></div>
            </Col>
          </Row>
        </section>

        <section>
          <Row>
            <div className="kammuiguides-keyvisual"></div>
          </Row>
          <Row>
            <div className="kammuiguides-wrapper">
              <Col lg={9} md={8} sm={8} xs={12} className="kammuiguides">
                <h2>
                  <FormattedMessage id="LandingPage.kammuiGuidesTitle" />
                </h2>
                <p>
                  <FormattedMessage id="LandingPage.leadingGuidesDescription" />
                </p>
                <p>
                  <FormattedMessage id="LandingPage.guidesOfferingInfo" />
                  <FormattedMessage id="LandingPage.conciergeInfo" />
                </p>
                <div className="button-textlink">
                  <NamedLink name="FeaturedGuidesLandingPage" id="textlink_meet_guides">
                    <FormattedMessage id="LandingPage.meetOurGuidesLink" />
                  </NamedLink>
                </div>
              </Col>
            </div>
          </Row>
          <Row>
            <div className="landing-page-separator"></div>
          </Row>
        </section>
      </Container>

      <Container fluid>
        <section className="activities-wrapper">
          <Row className="m-0">
            <Col lg={9} md={8} sm={8} xs={12} className="activities-section-title">
              <h2>
                <FormattedMessage id="LandingPage.activitiesTitle" />
              </h2>
            </Col>
          </Row>

          {activityTypes}
          <div className="landing-page-separator"></div>
        </section>
      </Container>

      <Container>
        <section className="reviews-wrapper">
          <Row className="reviews g-3">
            <Col lg={4} md={4} sm={12} xs={12}>
              <div className="review1">
                <p className="review">
                  <FormattedMessage id="LandingPage.review1Text" />
                </p>
                <p className="reviewer">
                  - <FormattedMessage id="LandingPage.reviewer1Name" />
                </p>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <div className="review2">
                <p className="review">
                  <FormattedMessage id="LandingPage.review2Text" />
                </p>
                <p className="reviewer">
                  - <FormattedMessage id="LandingPage.reviewer2Name" />
                </p>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <div className="review3">
                <p className="review">
                  <FormattedMessage id="LandingPage.review3Text" />
                </p>
                <p className="reviewer">
                  - <FormattedMessage id="LandingPage.reviewer3Name" />
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="landing-page-separator"></div>
          </Row>
        </section>
      </Container>

      <Footer showBanner={false} />
    </PageBootstrap>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  currentUser: propTypes.currentUser,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  featuredExperiences: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { data } = state.LandingPage;
  const featuredExperiences = data ? data : [];

  return {
    currentUser,
    featuredExperiences,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
